// Heading Color
$heading-color: #232323;

// Text Color
$text-color: #5f5f5f;

// Default Background
$bg-default: #000000;

// Gray Background
$bg-gray: #f5f9fa;

// Primary Color
$pri-color: #232323;

// White Color
$white-color: #ffffff;

// Dark Color
$dark-color: #000000;

// Border Color
$border-color: #eaeaea;

// Hover Color
$hover-color: #232323;

// Footer Color
$footer-color: #000000;

// Secondary Color
$secondary-color: #838383;